<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">报餐申请</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <van-form
        @submit="openDialog"
        input-align="right"
        error-message-align="right"
        ref="form"
      >
        <van-field
          readonly
          clickable
          name="picker"
          v-model="form.day"
          label="报餐时间"
          placeholder="请选择报餐时间"
          @click="showPicker = true"
          right-icon="arrow-down"
          class="company"
          :rules="[{ required: true, message: '请选择报餐时间' }]"
        >
          <!-- 自定义文本 -->
          <template #label>
            <span class="form-label">报餐时间</span>
          </template>
          <!-- 自定义箭头图标 -->
          <!-- <template #right-icon>
            <img
              src="../../../assets/icon-arrow-bottom.png"
              width="19px"
              height="19px"
              alt
          /></template> -->
        </van-field>
        <van-field
          name="checkboxGroup"
          label="报餐餐段"
          :rules="[{ required: true, message: '请填写餐段' }]"
        >
          <template #label>
            <span class="form-label">报餐餐段</span>
          </template>
          <template #input>
            <div class="check-box">
              <van-checkbox-group
                v-model="form.mealTimeId"
                direction="horizontal"
                icon-size="17px"
                @change="onChangeMealTime"
              >
                <van-checkbox
                  v-for="item in mealPeriodList"
                  :key="item.value"
                  :name="item.value"
                  shape="square"
                  checked-color="#C7000B"
                >
                  <!-- 自定义文本大小 -->
                  <template>
                    <span
                      style="
                        font-size: 18px;
                        font-weight: 400;
                        font-family: 'PingFangSC-Regular, sans-serif';
                        color: rgba(102, 102, 102, 1);
                      "
                    >
                      {{ item.label }}
                    </span>
                  </template>
                </van-checkbox>
              </van-checkbox-group>
            </div>
          </template>
        </van-field>
        <van-field label="预付费用">
          <template #label>
            <span class="form-label">预付费用</span>
          </template>
          <template #input>¥{{ money }}</template>
        </van-field>
        <div
          style="
            padding: 43px 16px 150px 16px;
            background-color: #f7f7f7 !important;
            height: 0 !important;
          "
        >
          <van-button
            square
            block
            type="info"
            native-type="submit"
            style="border-radius: 4px; font-size: 18px"
            color="#FA5050"
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- <van-dialog
      v-model="showDialog"
      show-cancel-button
      show-confirm-button
      :close-on-click-overlay="false"
      class="submit-dialog"
      confirm-button-color="#FA5050"
      cancel-button-color="#999999"
      overlay-class="overlay"
      @cancel="(showDialog = false), (show = false)"
      @confirm="onSubmit"
    >
      <template #title>
        <div class="title">温馨提示</div>
      </template>
      <div class="body">确定提交申请吗?</div>
    </van-dialog> -->

    <!-- 购买弹框 -->
    <van-popup
      v-model="showDialog"
      position="bottom"
      :style="{ height: '365px' }"
    >
      <div class="payment">
        <div class="payment_top">
          <img
            @click="showDialog = false"
            src="../../../assets/icon-pay-close.png"
            alt=""
          />
          <span class="title">支付信息</span>
        </div>
        <div class="amount">
          <div class="amount_top">
            {{ money }}
          </div>
          <div class="amount_bottom">订单金额(元)</div>
        </div>
        <div class="payType">
          <!-- <van-radio-group v-model="payType" checked-color="rgba(10,193,171)">
            <van-cell-group>
              <van-cell title="卡内余额" clickable @click="radio = 1">
                <template #right-icon>
                  <van-radio :name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group> -->
          <div class="item">
            <div class="label">支付方式</div>
            <div class="value">卡内余额</div>
          </div>
        </div>
        <div class="payment_footer">
          <div class="btn" @click="onSubmit">立即支付</div>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
    <van-calendar
      v-model="showPicker"
      @select="onConfirm"
      :show-confirm="false"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  getReportMealConfig,
  postReportMeal,
  postReportMealPay
} from '@/api/report-meal';
import cancel from '@/assets/cancel.png';
export default {
  data() {
    return {
      cancel,
      show: false,
      form: { mealTimeId: [], money: 0 },
      showPicker: false,
      mealPeriodList: [],
      showDialog: false
    };
  },
  computed: {
    money() {
      let money = 0;
      if (this.form.pay === 1) {
        this.constant.SUBMIT_MEAL_PERIOD.forEach((item) => {
          money +=
            (this.form[item.value] *
              this.form[item.money] *
              this.form.discount) /
            100;
        });
      } else {
        money = 0;
      }
      return parseFloat(money / 100).toFixed(2);
    }
  },
  async created() {
    await this.getConfig();
  },
  methods: {
    // 获取配置
    async getConfig() {
      try {
        this.show = true;
        const res = await getReportMealConfig();
        if (res.data) {
          this.form = {
            ...this.form,
            ...res.data
          };
          for (const key in this.form) {
            this.constant.SUBMIT_MEAL_PERIOD.forEach((item) => {
              if (item.value === key && this.form[key] === 1) {
                this.mealPeriodList.push(item);
                this.form.mealTimeId.push(item.value);
              }
            });
          }
          console.log(this.form, 'form');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    },

    toBack() {
      this.$router.back();
    },
    async openDialog() {
      // 校验表单
      console.log('校验通过');
      // 提交表单
      this.showDialog = true;
    },
    async onSubmit() {
      try {
        this.show = true;
        // 删除createTime属性
        delete this.form.createTime;
        const action = this.form.pay === 1 ? postReportMealPay : postReportMeal;
        this.form.money = 0;
        if (this.form.pay === 1) {
          this.constant.SUBMIT_MEAL_PERIOD.forEach((item) => {
            this.form.money +=
              (this.form[item.value] *
                this.form[item.money] *
                this.form.discount) /
              100;
          });
        } else {
          this.form.money = 0;
        }
        this.form.money = parseFloat(this.form.money).toFixed(2);
        this.form.money = Number(this.form.money);
        this.form.groupId = this.form.id;
        this.form.groupName = this.form.name;

        const res = await action(this.form);
        if (res.code === 0) {
          this.$toast.success('提交成功');
          this.$router.back();
        } else {
          this.$toast.fail(res.message || res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
        this.showDialog = false;
      }
    },
    onConfirm(value) {
      console.log(value);
      this.form.day = dayjs(value).format('YYYY-MM-DD');
      this.showPicker = false;
    },
    // 选择餐段
    onChangeMealTime(value) {
      console.log(value, '勾选餐段');
      this.constant.SUBMIT_MEAL_PERIOD.forEach((item) => {
        if (value.includes(item.value)) {
          this.form[item.value] = 1;
        } else {
          this.form[item.value] = 0;
        }
      });
      console.log(this.form, 'form');
    }
  }
};
</script>

<style scoped lang="scss">
.form-label {
  font-size: 16px;
  font-family: 'PingFangSC-Regular, sans-serif';
  color: #333333;
  font-weight: 400;
}

::v-deep .van-field__control::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(196, 196, 196, 1);
}
::v-deep .van-checkbox-group--horizontal {
  width: 150px;
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  justify-content: right;
  .van-checkbox--horizontal {
    flex: 0 0 43%;
    margin-right: 0 !important;
  }
  .van-icon-success {
    border-radius: 2px !important;
  }
}
/* 输入框填写文字样式 */
::v-deep .van-field__control {
  font-size: 16px !important;
  font-family: 'PingFangSC-Regular, sans-serif';
  color: #666666;
  font-weight: 400;
}

.container {
  font-family: 'PingFangSC-Regular';
  height: 100%;
  background-color: #f7f7f7;
  ::v-deep .van-dialog {
    border-radius: 10px;
    font-family: 'PingFangSC-Regular';
    width: 343px;
    height: 161px;
    box-sizing: border-box;
    .body {
      margin: 24px 0;
      text-align: center;
      font-weight: 400;
      color: #666666;
      font-size: 16px;
      box-sizing: border-box;
      line-height: 16px;
    }
  }
  ::v-deep .van-dialog__header {
    padding-top: 28px;
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 44px;
    color: white;
    overflow: hidden;
    /* border-bottom: 1px solid rgba(181, 201, 210, 0.6); */
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    margin-top: 54px;
    background-color: #fff;
  }
}

::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.payment {
  .payment_top {
    height: 50px;

    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .title {
      color: #666666;
      font-size: 18px;
      font-weight: 400;
      font-family: '苹方';

      margin-left: 118px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .amount {
    border-top: 1px solid rgba(215, 215, 215);
    color: #333333;
    .amount_top {
      text-align: center;
      opacity: 1;
      color: #333333;
      font-size: 36px;
      font-weight: 500;
      font-family: '苹方';
      text-align: center;
      line-height: 36px;
      margin-top: 31px;
    }
    .amount_bottom {
      text-align: center;
      margin-top: 8px;
    }
  }
  .payType {
    margin-top: 31px;
    padding-left: 16px;
    box-sizing: border-box;
    .item {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 1px 1px 0 #e5e5e5;
      padding-right: 16px;
      .value {
        color: #666666;
        font-size: 16px;
        font-weight: 400;
        font-family: '苹方';
      }
      .label {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        font-family: '苹方';
      }
    }
  }
  .payment_footer {
    position: fixed;
    bottom: 22px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 343px;
      height: 44px;
      background: #fa5050;
      border-radius: 4px;
      color: white;
      line-height: 44px;
      text-align: center;
    }
  }
}
</style>
